let isUserInfoFetched = false;

export const waitUserInfo = async (store) => {
  if (isUserInfoFetched) {
    return Promise.resolve();
  }

  isUserInfoFetched = true;

  return Promise.all([
    store.dispatch('loadClientData', null, { root: true }),
    store.dispatch('getFeatureFlags', null, { root: true }),
  ]);
};
