import dayjs from 'dayjs';

export const groupItemsByCreatedAt = items => {
  const groupedItems = {};

  items.forEach(item => {
    const createdAtDayTimestamp = dayjs(item.createdAt).startOf('day').unix();

    if (!groupedItems[createdAtDayTimestamp]) {
      groupedItems[createdAtDayTimestamp] = [];
    }

    groupedItems[createdAtDayTimestamp].push(item);
  });

  return groupedItems;
};
