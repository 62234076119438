const introHints = {
  mounted(element, binding) {
    element.setAttribute('data-intro', binding.value.dataIntro);
    element.setAttribute('data-step', binding.value.dataStep);
  },
  updated(element, binding) {
    if (binding.value.dataIntro !== binding.oldValue.dataIntro) {
      element.setAttribute('data-intro', binding.value.dataIntro);
    }
  },
};

export default introHints;
